import {Button, createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ErrorIcon from '@material-ui/icons/Error'
import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        typography: {
            padding: theme.spacing(2),
        }
    }),
);

interface Props {
    statusResponse: any;
}

export default function UnhealthyServerButton({ statusResponse }: Props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{marginLeft: 'auto'}}>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<ErrorIcon />}
                onClick={handleClick}
            >
                Server is down!
            </Button>
            <Popover
                style={{marginLeft: 'auto'}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>Error - cannot connect to server.</Typography>
            </Popover>
        </div>
        )

}
