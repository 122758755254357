import Grid from "@material-ui/core/Grid";
import {DropzoneArea} from "material-ui-dropzone";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, createStyles} from "@material-ui/core";
import {useAuth0} from '@auth0/auth0-react';
import axios, {AxiosRequestConfig} from 'axios';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210,
    }
}));

export function LiveweightUploadComponent() {

    const {getAccessTokenSilently} = useAuth0();

    let auth0_token: any;

    const getAuth0TokenAsync = async () => {
        await getAccessTokenSilently().then(data => auth0_token = data);
    }

    getAuth0TokenAsync();

    const classes = useStyles();
    let liveweightFiles: File[] = []

    function submitLiveweightFiles() {

        let formData = new FormData();
        liveweightFiles.forEach(file => {
            formData.append("incomingFiles", file, file.name)
        })

        const config: AxiosRequestConfig = {
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_BASE_URL}/${process.env.REACT_APP_ANIMAL_WELLNESS_PATH}/${process.env.REACT_APP_LIVEWEIGHT_PATH}`,
            headers: {
                'Authorization': `Bearer ${auth0_token}`
            },
            data: formData
        };

        try {
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    console.log(error);
                })
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs/>
                <Grid item xs={6}>
                    <DropzoneArea
                        onChange={(files) => liveweightFiles = files}
                        filesLimit={10}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                        previewChipProps={{classes: {root: classes.previewChip}}}
                        previewText="Selected files"
                    />
                </Grid>
                <Grid item xs/>
            </Grid>
            <Button style={{marginTop: '1.5rem'}} variant='outlined' color='inherit'
                    onClick={() => submitLiveweightFiles()}>Upload</Button>
        </div>
    );
}
