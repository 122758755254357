import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button} from "@material-ui/core";

const LoginButton = () => {
    const {loginWithRedirect} = useAuth0();

    return <Button style={{marginLeft: '1rem'}} variant='outlined' color='inherit' onClick={() => loginWithRedirect()}>Log
        In</Button>;
};

export default LoginButton;
