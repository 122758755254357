import React from 'react';
import logo from './assets/logo.svg';
import 'fontsource-roboto';
import './App.css';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import NavigationBar from "./components/navigation/appbar";
import Typography from "@material-ui/core/Typography";
import {LiveweightUploadComponent} from "./components/liveweightUpload/uploadComponent";

const ProtectedApp = () => (
    <div className="App">
        <NavigationBar/>

        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <Typography variant="h4" style={{flexGrow: 1, margin: '1.5rem'}}>
                MINDA Liveweight Uploader <sup className="Semi-semi-official-tag">Semi-semi Official</sup>
            </Typography>
        </header>

        <LiveweightUploadComponent/>

    </div>
);

export default withAuthenticationRequired(ProtectedApp, {
    onRedirecting: () => (
        <header className="App-header">
            <Typography variant="h4" style={{flexGrow: 1, margin: '1.5rem'}}>
                You need to be logged in to see this page, we're redirecting you now...
            </Typography>
        </header>
    )
});
