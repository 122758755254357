import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AuthButton from "../auth0/authButton";
import styled from "@material-ui/core/styles/styled";
import HealthButtonComponent from "./healthButton";

const NavigationBarStyles = styled(AppBar)({
    background: '#001E62'
});

export default function NavigationBar() {
    return <NavigationBarStyles position="sticky">
        <Toolbar>
            <HealthButtonComponent/>
            <AuthButton/>
        </Toolbar>
    </NavigationBarStyles>;
}
