import React from 'react';
import axios from 'axios';
import HealthyServerButton from "./healthyServerButton";
import UnhealthyServerButton from "./unhealthyServerButton";

const HealthButtonComponent = () => {
    const [isServerUp, setServerIsUp] = React.useState(true);
    const [serverResponse, setServerResponse] = React.useState('');
    React.useEffect(() => {
        // setup the timeout interval to go
        const getServerStatusTimer = setInterval(async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/${process.env.REACT_APP_ANIMAL_WELLNESS_PATH}/health`
                );
                setServerResponse(response.data);
                setServerIsUp(response.status === 200);
            } catch (e) {
                console.error(e);
                setServerIsUp(false);
                setServerResponse(e);
            }
        }, 5000);
        // cleanup when component dismounts
        return () => {
            clearInterval(getServerStatusTimer);
        };
    }, [serverResponse]);
    if (isServerUp) {
        return <HealthyServerButton statusResponse={serverResponse}/>;
    } else {
        return <UnhealthyServerButton statusResponse={serverResponse}/>;
    }
};

export default HealthButtonComponent;
