import {Button, createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            background: 'green'
        },
        typography: {
            padding: theme.spacing(2),
        }
    }),
);

interface Props {
    statusResponse: any;
}

export default function HealthyServerButton({statusResponse}: Props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{marginLeft: 'auto'}}>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CheckCircleIcon/>}
                onClick={handleClick}
            >
                Server is up
            </Button>
            <Popover
                style={{marginLeft: 'auto'}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>Overall Status: {statusResponse?.status}</Typography>
                <Typography className={classes.typography}>Animal Wellness Internals: {statusResponse.results?.animalWellnessServiceInternals.status}</Typography>
                <Typography className={classes.typography}>Database Connection: {statusResponse.results?.rdsPostgresDatabase.status}</Typography>
                <Typography className={classes.typography}>Assembly Version: {statusResponse.results?.animalWellnessServiceInternals.data.assemblyVersion}</Typography>
                <Typography className={classes.typography}>Git Hash: {statusResponse.results?.animalWellnessServiceInternals.data.gitHash}</Typography>
            </Popover>
        </div>
    )
}
